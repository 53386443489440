import BigNumber from "bignumber.js";
import { factoryAbi, tokenAbi } from "contract";
import { AssetData, BaseToken } from "types/token";
import { slice } from "utils/strings";
import { baseToken } from "../constants/token";
import { getIpfsURL } from "utils/ipfs";
import { dexAddress, factoryAddress, wtrxAddress } from "../constants";

export const getAllAssets = async (provider: any): Promise<AssetData[]>  => {
    let factoryContract = await provider.contract(factoryAbi, factoryAddress);
    let tokens = await factoryContract.getAllIndexTokens().call();

    const data: AssetData[] = await Promise.all(
        tokens.map(async (addr: any) => {
            let tokenContract = await provider.contract(tokenAbi, addr);
            let _name = await tokenContract.name().call();
            let _symbol = await tokenContract.symbol().call();
            let _image = await tokenContract.image().call();
            let _decimals = await tokenContract.decimals().call();
            let _creator = await tokenContract.getCreator().call();
            let _totalSupply = await tokenContract.totalSupply().call();
            let _underlyingTokens = await tokenContract.getUnderlyingTokens().call();
            let _baseToken = _underlyingTokens.map((u: string) => baseToken[provider.address.fromHex(u)])
            return {
                name: _name,
                symbol: _symbol,
                address: provider.address.fromHex(addr),
                creator: slice(provider.address.fromHex(_creator)),
                image: _image,
                decimals: Number(_decimals.toString()),
                totalSupply: new BigNumber(_totalSupply.toString()),
                underlyingTokens: _baseToken
            }
    }));

    return data
}

export const getIndexToken = async (provider: any, addr: any): Promise<AssetData>  => {
    let tokenContract = await provider.contract(tokenAbi, addr);
    let data = await tokenContract.getTokenDetails().call();
    const creatorPercentage = await tokenContract.feePercentage().call();
    let _baseToken: BaseToken[] = data.underlyingTokens.map((u: string, i: number) => { 
        let tData = baseToken[provider.address.fromHex(u)]
        let amount = new BigNumber(data.underlyingTokenAmounts[i].toString())
        return {...tData, amount: amount}
    })

    return {
        name: data.name,
        symbol: data.symbol,
        address: provider.address.fromHex(addr),
        creator: slice(provider.address.fromHex(data.creator)),
        image: getIpfsURL(data.image),
        description: getIpfsURL(data.description),
        decimals: Number(data.decimals.toString()),
        totalSupply: new BigNumber(data.totalSupply.toString()),
        underlyingTokens: _baseToken,
        creatorPercentage: Number(creatorPercentage.toString()),
    }
}

export const getAllIndexTokens = async (provider: any): Promise<AssetData[]>  => {
    let factoryContract = await provider.contract(factoryAbi, factoryAddress);
    let tokens = await factoryContract.getAllIndexTokens().call();

    const data: AssetData[] = await Promise.all(
        tokens.map(async (addr: any) => {
            return getIndexToken(provider, addr)
    }));
    return data
}

export const getUnderlyingTokenAmounts = async (provider: any, addr: string): Promise<string[]>  => {
    let tokenContract = await provider.contract(tokenAbi, addr);
    let amounts = await tokenContract.getUnderlyingTokenAmounts().call();
    return amounts;
}

export const getTotalTrxToBuyIndexTokens = async (provider: any, addr: string, amount: BigNumber) : Promise<BigNumber> => {
    let tokenContract = await provider.contract(tokenAbi, addr);
    let trxAmount = await tokenContract.calculateTotalTrxToBuyIndexTokensWithFee(amount.toFixed()).call();
    return new BigNumber(trxAmount.toString())
}

export const getTotalTrxAfterSellIndexTokens = async (provider: any, addr: string, amount: BigNumber) : Promise<BigNumber> => {
    let tokenContract = await provider.contract(tokenAbi, addr);
    let trxAmount = await tokenContract.calculateTotalTrxAfterSellIndexTokens(amount.toString()).call();
    return new BigNumber(trxAmount.toString())
}

export const getIndexTokenBalance = async (provider: any, addr: string, contractAddr: string): Promise<BigNumber> => {
    let tokenContract = await provider.contract(tokenAbi, contractAddr);
    let amount = await tokenContract.balanceOf(addr).call();
    return new BigNumber(amount.toString())
}

export const buyIndexToken = async (provider: any, wallet: any, contractAddr: string, trxAmount: BigNumber, indexTokenAmount: BigNumber) => {
    var parameter = [
        { type: "uint256", value: indexTokenAmount.toFixed() },
    ];

    var options = { feeLimit: 300000000, callValue: trxAmount.toFixed(), txLocal: true };
    let funcDef = "mint(uint256)";
    const tx = await provider.transactionBuilder.triggerSmartContract(
      contractAddr,
      funcDef,
      options,
      parameter
    );

    const signedTx = await wallet.signTransaction(tx.transaction);
    const result = await provider.trx.sendRawTransaction(signedTx);
    return result.txid
}

export const sellIndexToken = async (provider: any, wallet: any, contractAddr: string, indexTokenAmount: BigNumber, slippage: number) => {
    var parameter = [
        { type: "uint256", value: indexTokenAmount.toFixed() },
        { type: "uint8", value: slippage },
    ];

    var options = { feeLimit: 300000000, callValue: 0, txLocal: true };
    let funcDef = "redeem(uint256,uint8)";
    const tx = await provider.transactionBuilder.triggerSmartContract(
      contractAddr,
      funcDef,
      options,
      parameter
    );

    const signedTx = await wallet.signTransaction(tx.transaction);
    const result = await provider.trx.sendRawTransaction(signedTx);
    return result.txid
}

export const createIndexToken = async (
    provider: any,
    wallet: any,
    underlyingTokens: string[],
    underlyingTokensAmount: BigNumber[],
    underlyingTokensDecimals: number[],
    name: string,
    symbol: string,
    image: string,
    description: string,
    tokenDecimals: number,
    creatorPercentage: number
) => {
    var parameter = [
        { type: "address", value: wtrxAddress },
        { type: "address", value: dexAddress },
        { type: "address[]", value: underlyingTokens },
        { type: "uint256[]", value: underlyingTokensAmount.map(u => u.toFixed()) },
        { type: "uint8[]", value: underlyingTokensDecimals },
        { type: "string", value: name },
        { type: "string", value: symbol },
        { type: "string", value: image },
        { type: "string", value: description },
        { type: "uint8", value: tokenDecimals },
        { type: "uint8", value: creatorPercentage },
    ];

    var options = { feeLimit: 500000000, callValue: 0, txLocal: true };
    let funcDef = "createIndexToken(address,address,address[],uint256[],uint8[],string,string,string,string,uint8,uint8)";
    const tx = await provider.transactionBuilder.triggerSmartContract(
      factoryAddress,
      funcDef,
      options,
      parameter
    );

    const signedTx = await wallet.signTransaction(tx.transaction);
    const result = await provider.trx.sendRawTransaction(signedTx);
    return result.txid
}
