// @ts-ignore
import TronWeb from 'tronweb';
import { NetworkType } from '@tronweb3/tronwallet-abstract-adapter';

export { baseToken } from './token';

export const factoryAddress = "TRzMNf1WwXByiA8tYeJpDuXm5MWd2mqivn";
export const wtrxAddress = "TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR";
export const dexAddress = "TKzxdSv2FZKQrEqkKVgp5DcwEXBEKMg2Ax";
export const apiKey = "89d9f463-49e8-48b2-afe1-d705565d920f";

export const currentNetwork: NetworkType = NetworkType.Mainnet

export function getTronWebConfig(networkType: NetworkType): TronWeb {
    switch (networkType) {
        case NetworkType.Mainnet:
            return new TronWeb({
                fullHost: 'https://api.trongrid.io',
                headers: { 'TRON-PRO-API-KEY': apiKey },
            });
        case NetworkType.Shasta:
            return new TronWeb({
                fullHost: 'https://api.shasta.trongrid.io',
                headers: { 'TRON-PRO-API-KEY': apiKey },
            });
        case NetworkType.Nile:
            return new TronWeb({
                fullHost: 'https://nile.trongrid.io',
                headers: { 'TRON-PRO-API-KEY': apiKey },
            });
        default:
            return new TronWeb(
                "http://127.0.0.1:9090",
                "http://127.0.0.1:9090",
                "http://127.0.0.1:9090"
            );
    }
}

export let defaultProvider = getTronWebConfig(currentNetwork)