import BigNumber from "bignumber.js";

export const slice = (input: string | undefined): string => {
    if (input === undefined) {
        return '';
    }
    
    if (input.length <= 5) {
        return input;
    }

    return `${input.slice(0, 5)}...${input.slice(input.length-5)}`;
}

export const getDisplayAmount = (amount?: BigNumber, decimals?: number, fixed: number = 3, removePadding: boolean = true): string => {
    if(amount && decimals) {
        let a = new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals)).toFixed(fixed)
        return removePadding ? parseFloat(a).toString() : a
    } 
    return ""
}

export const getDisplayTrx = (amount: BigNumber, fixed: number = 3): string => {
    return getDisplayAmount(amount, 6, fixed)
}