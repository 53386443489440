
import { pinataApiKey, pinataApiSecret, pinataTokenJWT } from "./constants";

type OutputData = {
  data: string;
}

var config = {
  method: 'post',
  headers: { 
    'Content-Type': 'application/json', 
    'pinata_api_key': pinataApiKey,
    'pinata_secret_api_key': pinataApiSecret
  }
};

export const handleUploadJsonToIpfs = async(jsonFile: OutputData) => {
    try {
        const res = await fetch('https://api.pinata.cloud/pinning/pinJSONToIPFS', {
            ...config,
            body: JSON.stringify(jsonFile)
        });

        const data = await res.json()
        console.log("Pinata ", data)
        return data.IpfsHash
    } catch(e) {
        console.error(e)
    }
}


