import { useCallback, useEffect, useState } from "react"
import { TokenIcon } from "../pages/ExploreBasket"
import { debounce } from "../utils/debounce"
import { useRecoilValue } from "recoil"
import { subscribeTxStatus } from "../utils/subscribeTxStatus"
import classNames from "classnames"
import { AssetData } from "types/token"
import { getTimestamp10MinutesAfterCurrentTime } from "utils/getTimestamp10MinutesAfterCurrentTime"
import BigNumber from "bignumber.js"
import { getIndexTokenBalance, getTotalTrxAfterSellIndexTokens, getTotalTrxToBuyIndexTokens, sellIndexToken } from "contract/scripts"
import { getDisplayAmount, getDisplayTrx } from "utils/strings"
import { useCurrentWallet } from "WalletProvider"
import React from "react"
import { toast } from "react-toastify"

export type IssueTokenModalProps = {
    onClose: () => void,
    basketDetails?: AssetData,
}

function RedeemTokenModal({ onClose, basketDetails}: IssueTokenModalProps) {
    const [value, setValue] = useState<number>()
    const [totalTrx, setTotalTrx] = useState("0")
    const [disable, setDisable] = useState(true)
    const [tokenBal, setTokenBal] = useState<BigNumber>();
    const { provider, address, wallet, connected } = useCurrentWallet()
   
    const slippage = 5 // 5%
        
    const delayCalculateTrx = useCallback(debounce(async () => {
        setDisable(true)
        if(value && value > 0 && basketDetails){
            let tokenAmount = new BigNumber(value).mul(new BigNumber(10).pow(basketDetails.decimals));
            let requiredTrx = await getTotalTrxAfterSellIndexTokens(provider, basketDetails.address, tokenAmount);
            setTotalTrx(getDisplayTrx(requiredTrx))
            setDisable(false)
        } else setTotalTrx("0")
    }, 2000), [value, basketDetails]);

    useEffect(() => {
        delayCalculateTrx()
    }, [value])

    const handleIssue = async() => {     
        try {
            if (basketDetails && totalTrx && connected && address) {
                let sellAmount = new BigNumber(value).mul(new BigNumber(10).pow(basketDetails.decimals));
                const txId = await sellIndexToken(provider, wallet, basketDetails.address, sellAmount, slippage);
                subscribeTxStatus(txId, provider);
                await setTokenBalance();
            }
        } catch (e) {
            console.log(e);
            toast.error("Transaction failed");
        }
    }

    const setTokenBalance = async () => {
        const bal = await getIndexTokenBalance(provider, address, basketDetails.address)
        setTokenBal(bal)
    }

    const isLoggedIn = connected && address

    useEffect(() => {
        if(isLoggedIn && basketDetails) {
          (async () => {
            await setTokenBalance()
          })()
        }
    }, [isLoggedIn, basketDetails])

    return (
        <div className="bg-white-100 w-96 mx-4 p-4 rounded-xl">
            <div className="flex justify-center flex-col items-center border-b border-gray-200 py-3" >
                <div className="flex w-full px-5 items-center justify-between">
                    <p className="text-xl font-bold text-gray-800">Redeem {basketDetails?.symbol}</p>
                    <div onClick={onClose} className=" hover:bg-gray-500 cursor-pointer hover:text-gray-300 font-sans text-gray-500 w-8 h-8 flex items-center justify-center rounded-full" > X </div>
                </div>
            </div>
            <div className=" w-full flex flex-col items-center justify-center rounded-b-lg">
                <div className="flex flex-col items-center w-5/6 gap-2 rounded-lg">
                    <input
                        value={value}
                        onChange={(e) => setValue(Number(e.target.value))}
                        placeholder={`Add amount to redeem`}
                        type="number"
                        className="rounded-md text-base w-full px-6 py-2 mt-8 focus:outline-none bg-gray-50 border-[1px] border-gray-200"
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    />
                     {tokenBal && <div className="flex flex-col w-full">
                        <div className="flex w-full justify-between">
                            <p className="text-sm text-gray-400">Balance: </p>
                            <p className="text-sm text-red-600">{getDisplayAmount(tokenBal, basketDetails.decimals)} {basketDetails?.symbol}</p>
                        </div>
                        <div className="flex w-full justify-between">
                            <p className="text-sm text-gray-400">Slippage: </p>
                            <p className="text-sm text-red-600">{slippage} %</p>
                        </div>
                    </div>}
                        <div className="flex justify-between w-full pt-6 ">
                            <p>Redeemable Trx amount</p>
                            <p>{totalTrx ?? 0} TRX</p>
                        </div>
                    </div>
                    <button disabled={disable} onClick={handleIssue} 
                        className={classNames("text-white-100 font-bold py-2 my-2 px-6 rounded-lg cursor-pointer w-5/6", {
                            "bg-gray-400": disable,
                            "bg-red-600 hover:bg-red-700": !disable
                        })}>Redeem</button>
                </div>
            </div>
    )
}

export default RedeemTokenModal