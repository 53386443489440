import { useCallback, useEffect, useState } from "react"

import { TokenIcon } from "../pages/ExploreBasket"

import { debounce } from "../utils/debounce"
import { subscribeTxStatus } from "../utils/subscribeTxStatus"

import classNames from "classnames"
import { AssetData } from "types/token"
import React from "react"
import { getDisplayAmount, getDisplayTrx } from "utils/strings"
import { buyIndexToken, getIndexTokenBalance, getTotalTrxToBuyIndexTokens } from "contract/scripts"
import { useCurrentWallet } from "WalletProvider"
import BigNumber from "bignumber.js"
import { toast } from "react-toastify"

export type IssueTokenModalProps = {
    onClose: () => void,
    basketDetails?: AssetData,
    type: "issue" | "redeem"
}

function IssueTokenModal({ onClose, basketDetails, type}: IssueTokenModalProps) {
    const msg = type == "issue" ? "Buy": "Redeem"
    const [value, setValue] = useState(0)
    const [totalTrx, setTotalTrx] = useState<BigNumber | null>()
    const [disable, setDisable] = useState(true)
    const { provider, wallet, connected, address } = useCurrentWallet()
        
    const delayCalculateTrx = useCallback(debounce(async () => {
        setDisable(true)
        if(value > 0 && basketDetails){
            let tokenAmount = new BigNumber(value).mul(new BigNumber(10).pow(basketDetails.decimals));
            let requiredTrx = await getTotalTrxToBuyIndexTokens(provider, basketDetails.address, tokenAmount);
            setTotalTrx(requiredTrx)
            setDisable(false)
        } else setTotalTrx(null)
    }, 2000), [value, basketDetails]);

    useEffect(() => {
        delayCalculateTrx()
    }, [value])

    const handleIssue = async() => {
        try {
            if (basketDetails && totalTrx && connected && address) {
                let buyAmount = new BigNumber(value).mul(new BigNumber(10).pow(basketDetails.decimals));
                const txId = await buyIndexToken(provider, wallet, basketDetails.address, totalTrx, buyAmount);
                subscribeTxStatus(txId, provider);
            }
        } catch (e) {
            console.log(e);
            toast.error("Transaction failed");
        }
    }

    return (
        <div className="bg-white-100 w-96 mx-4 p-4 rounded-xl">
            <div className="flex justify-center flex-col items-center border-b border-gray-200 py-3" >
                <div className="flex w-full px-5 items-center justify-between">
                    <p className="text-xl font-bold text-gray-800">{msg} {basketDetails?.symbol}</p>
                    <div onClick={onClose} className=" hover:bg-gray-500 cursor-pointer hover:text-gray-300 font-sans text-gray-500 w-8 h-8 flex items-center justify-center rounded-full" > X </div>
                </div>
            </div>
            <div className=" w-full flex items-center justify-center rounded-b-lg">
                <div className="flex flex-col items-center w-5/6 gap-2 rounded-lg">
                    <input
                        value={value}
                        onChange={(e) => setValue(Number(e.target.value))}
                        placeholder={`Add number of tokens to ${msg.toLowerCase()}`}
                        type="number"
                        className="rounded-md text-base w-full px-6 py-2 mt-8 focus:outline-none bg-gray-50 border-[1px] border-gray-200"
                    />
                    <div className="font-bold text-sm text-red-600 cursor-pointer underline self-start mt-4">Underlying tokens value</div>
                    <div className=" flex flex-col w-full">
                        {
                            basketDetails?.underlyingTokens.map((t) => (
                                <div className="flex justify-between w-full py-2">
                                    <TokenIcon logo={t?.logo} symbol={t?.name} />
                                    {t?.amount && <p>{getDisplayAmount(t.amount.mul(value), t.decimals)} {t?.symbol}</p>}
                                </div>
                            ))
                        }
                         <div className="flex justify-between w-full pt-6 pb-2 text-red-600 text-sm">
                            <p>Creator Fee</p>
                            <p>{basketDetails.creatorPercentage} %</p>
                        </div>
                        <div className="flex justify-between w-full">
                            <p>Total Trx required</p>
                            <p>{totalTrx ? getDisplayTrx(totalTrx) : 0} Trx</p>
                        </div>
                    </div>
                    <button disabled={disable} onClick={handleIssue} className={
                        classNames(" text-white-100 font-bold py-2 my-2 px-6 rounded-lg cursor-pointer w-full", {
                            "bg-gray-400": disable,
                            "bg-red-600 hover:bg-red-700": !disable
                        })}>{msg}</button>
                </div>
            </div>
        </div>
    )
}

export default IssueTokenModal