import { useEffect, useState } from "react";
import Dashboard_Navbar from "../components/Dashboard_Navbar";

import { BookLogo, TokenLogo } from "images";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

import React from "react";
import { getAllAssets, getAllIndexTokens } from "contract/scripts";
import { useCurrentWallet } from "WalletProvider";
import { AssetData, BaseToken } from "types/token";
import { baseToken } from "constants/token";
import BigNumber from "bignumber.js";

const investmentThemes = [
  {
    title: "Growth Index",
    subTitle: "High momentum assets",
    id: 1,
  },
  {
    title: "Stable Index",
    subTitle: "Coming soon",
    id: 2,
  },
];

export const basketsAtom = atom<AssetData[]>({
  key: 'baskets',
  default: undefined
})

function ExploreDashboard() {
  const [selectedId, setSelectedId] = useState(1);
  const [baskets, setBaskets] = useRecoilState(basketsAtom)
  const [filteredBaskets, setFilteredBaskets] = useState<AssetData[]>([]);
  const { provider } = useCurrentWallet();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    (async() => {
      let data = await getAllIndexTokens(provider)
      console.log(data)
      setBaskets(data)
      setFilteredBaskets(data)
    })()
  }, [])

  useEffect(() => {
    const items = baskets?.filter(b => b.name.toLowerCase().includes(searchTerm.toLowerCase())) ?? []
    setFilteredBaskets(items)
    console.log(items)
  }, [searchTerm])
  
  return (
    <div className="flex flex-col items-center bg-red-50 min-h-screen">
      <Dashboard_Navbar navbarShadow />
      <div className="flex flex-col items-center w-4/6 mt-40">
        <div className="text-custom-600 mb-14 font-semibold text-2xl">
          Explore Investment Baskets created on Asset Fusion Protocol
        </div>
        <div className="flex w-full text-sm gap-8 m-auto">
          {investmentThemes.map((theme) => (
            <ThemeCard
              {...theme}
              onClick={(id: number) => setSelectedId(id)}
              selected={theme.id == selectedId}
            />
          ))}
        </div>
        <div className="flex flex-col w-full bg-white border-[1px] gap-y-12 border-gray-100 bg-white-100 p-10 my-12 rounded-2xl text-sm">
          <input
            placeholder="Search token by name"
            value={searchTerm}
            className="rounded-full w-1/3 text-base px-6 py-4 focus:outline-none  border-[1px] border-gray-200"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="border-[1px] w-full border-gray-100 rounded-md">
            <div className="flex w-full p-4 text-gray-400">
              <p className="w-2/6">Name</p>
              <p className="w-2/6">Symbol</p>
              <p className="w-1/6">Supply</p>
              <p className="w-1/6">Creator</p>
            </div>
            {filteredBaskets?.map((token) => (
              <TokenDetailCard basketDetails={token} />
            ))}
            { filteredBaskets.length ===0 &&
              <div className=" p-7 flex justify-center bg-gray-50" >No Assets Found</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

type ThemeCardProps = {
  title: string;
  subTitle: string;
  id: number;
  selected: boolean;
  onClick: (id: number) => void;
};

const ThemeCard = ({
  title,
  subTitle,
  id,
  selected = id == 0,
  onClick,
}: ThemeCardProps) => {
  return (
    <div
      onClick={() => onClick(id)}
      className={classNames(
        "border-[1px] h-[88px] w-[270px]  rounded-xl p-4 flex gap-3 cursor-pointer items-center",
        {
          "bg-white-100 border-custom-500": selected,
          "bg-gray-50 border-gray-600": !selected,
        }
      )}
    >
      <div
        className={classNames(
          "w-[54px] border-[1px] h-full flex items-center justify-center    rounded-md",
          {
            "border-custom-500 bg-custom-400": selected,
            "border-gray-600 bg-gray-50": !selected,
          }
        )}
      >
        <img src={selected ? TokenLogo : BookLogo} />
      </div>
      <div
        className={classNames("flex flex-col", {
          "text-custom-500": selected,
          "text-gray-700": !selected,
        })}
      >
        <p className="font-medium text-[16px]">{title}</p>
        <p className="text-xs">{subTitle}</p>
      </div>
    </div>
  );
};

type BasketDetailCardProps = {
  basketDetails: AssetData;
};

const TokenDetailCard = ({ basketDetails }: BasketDetailCardProps) => {
  const navigate = useNavigate();
  const {name, creator, decimals, image, symbol, totalSupply, underlyingTokens, address} = basketDetails

  let supply = totalSupply.dividedBy(new BigNumber(10).pow(decimals)).toString()
  
  return (
    <>
      <hr className="bg-gray-300" />
      <div className="flex cursor-pointer w-full p-4 hover:scale-[1.01] hover:shadow-md hover:shadow-gray-200 text-gray-600" onClick={() => navigate(`/asset/${address}`)}>
        <div className="w-2/6 pl-2 flex gap-4">
          <img src={image} className="h-10 w-10 rounded-full bg-red-50" />
          <p className="font-semibold text-gray-600">{name}</p>
        </div>
        <div className="w-2/6 flex flex-col gap-4">
          <p className="font-semibold text-gray-600">{symbol}</p>
          <div className="flex gap-2">
            {underlyingTokens
              .map((t) => (
                <TokenIcon token={t} />
              ))}
          </div>
        </div>
        <p className="w-1/6">{supply}</p>
        <p className="w-1/6">{creator}</p>
      </div>
    </>
  );
};

type TokenIconProps = {
  token: BaseToken;
};

const TokenIcon = ({ token }: TokenIconProps) => {
  return (
    <div className="flex items-center gap-1 text-xs">
      {token?.logo && <img src={token?.logo} className="h-4 w-4 rounded-full" />}
      {token?.symbol && <p>{token?.symbol}</p>}
    </div>
  );
};
export default ExploreDashboard;
