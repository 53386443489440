import Dashboard_Navbar from "../components/Dashboard_Navbar"
import logo from '../assets/react.svg'
// import { allTokens, getTokenByIdentifier, getTokenBySymbol } from "../utils/getTokenDetails";
import { useNavigate, useParams } from "react-router-dom";
// import { TokenDetails } from "./ExploreDashboard";
import { useEffect, useState } from "react";
//import IssueTokenModal from "../components/IssueModal";
import { useRecoilValue } from "recoil";
import { basketsAtom } from "./ExploreDashboard";
// import RedeemTokenModal from "../components/RedeemModal";
import React from "react";
import { AssetData, BaseToken } from "types/token";
import { baseToken } from "constants/token";
import { getIndexToken, getIndexTokenBalance } from "contract/scripts";
import { useCurrentWallet } from "WalletProvider";
import BigNumber from "bignumber.js";
import IssueTokenModal from "components/IssueModal";
import { getDisplayAmount } from "utils/strings";
import RedeemTokenModal from "components/RedeemModal";
import Skeleton from "react-loading-skeleton";

function ExploreBasket() {
    const [showIssueModal, setShowIssueModal] = useState(false)
    const [showRedeemModal, setShowRedeemModal] = useState(false)
    const [basketDetails, setBasketDetails] = useState<AssetData>()
    const [basketDescription, setBasketDescription] = useState<string>("")
    const [tokenBal, setTokenBal] = useState<BigNumber>();
    const { provider, address, connected } = useCurrentWallet();
    const baskets = useRecoilValue(basketsAtom)
    const { contractAddress } = useParams()

    useEffect(() => {
        (async () => {
            if (contractAddress && baskets) {
                let asset = baskets.find((b) => b.address == contractAddress);
                setBasketDetails(asset);
            } else {
                let asset = await getIndexToken(provider, contractAddress);
                setBasketDetails(asset);
            }
        })()
    }, [contractAddress, baskets])

    useEffect(() => {
        if (!basketDetails) return;
        (async () => {
            let data = await fetch(basketDetails.description).then(
                (res) => res.json()
            );

            setBasketDescription(data.data)
        })()

    }, [basketDetails])

    useEffect(() => {
        if (!basketDetails || !connected) return;
        setBalance()
    }, [basketDetails, showIssueModal, showRedeemModal, connected])

    const setBalance = async () => {
        const bal = await getIndexTokenBalance(provider, address, basketDetails.address)
        setTokenBal(bal)
    }

    const navigate = useNavigate()

    return (
        <>
            <div className="flex flex-col items-center h-full bg-red-50">
                <Dashboard_Navbar navbarShadow />
                <div className="flex flex-col w-2/3 mt-40 h-screen bg-custom-400">
                    <div onClick={() => navigate("/")} className="font-semibold text-base cursor-pointer mb-7 px-1 text-gray-400">{"<"} Back to explore</div>
                    <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                            <img className="h-[50px] w-[50px] bg-red-50 rounded-full mr-4" src={basketDetails?.image} />
                            <div className="flex flex-col">
                                <p className="text-4xl font-medium">{basketDetails?.name}</p>
                                <p className="text-red-600 font-semibold text-2xl">{basketDetails?.symbol}</p>
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <button onClick={() => setShowIssueModal(true)} className="bg-red-600 hover:bg-red-700 text-white-100 font-bold py-2 px-6 rounded-lg cursor-pointer min-w-[140px] mt-4">Buy</button>
                            <button onClick={() => setShowRedeemModal(true)} className="bg-red-600 hover:bg-red-700 text-white-100 font-bold py-2 px-6 rounded-lg cursor-pointer min-w-[140px] mt-4">Redeem</button>
                        </div>
                    </div>
                    <div className="border-[1px] w-full border-gray-200 rounded-md my-10 bg-white-100">
                        <div className="flex justify-between w-full py-4 text-sm px-10 text-gray-400">
                            <p className="">Token</p>
                            <p className="">Quantity per Fusion</p>
                        </div>
                        <hr className="bg-gray-200" />
                        <div className="flex justify-between w-full py-7 px-10 ">
                            <TokenIcon logo={basketDetails?.image} symbol={basketDetails?.name} />
                        </div>
                        <div className="py-2 px-10 bg-red-50 text-sm font-semibold text-gray-500">
                            Underlying Tokens
                        </div>
                        {
                            basketDetails?.underlyingTokens.map((t) => (
                                <div className="flex justify-between w-full py-6 px-10 ">
                                    <TokenIcon logo={t?.logo} symbol={t?.name} />
                                    {t?.amount && <p>{getDisplayAmount(t?.amount, t?.decimals, 3, false)} {t?.symbol}</p>}
                                </div>
                            ))
                        }
                        <hr className="bg-gray-200" />
                            {tokenBal && <div className="flex justify-between w-full py-6 px-10 text-sm ">
                                    <span className="text-gray-400">Your Balance</span>
                                    <p className="text-red-400">{getDisplayAmount(tokenBal, basketDetails.decimals)} {basketDetails?.symbol}</p>
                                </div>}
                    </div>
                    <div className="flex-col">
                        <div className="text-black-100 font-medium text-2xl mt-4">About</div>
                        <div className="w-full h-[1px] bg-gray-400 my-4"></div>
                        {basketDescription ? <p className="text-gray-500">
                            {basketDescription}
                        </p> : <Skeleton count={1} className="h-28 bg-red-100" />
                        }
                        
                    </div>
                </div>
            </div>
            {showIssueModal && (
                <div>
                    <div
                        onClick={() => setShowIssueModal(false)}
                        className="absolute top-0 left-0 w-screen h-screen bg-gray-600 opacity-50"
                    ></div>
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <IssueTokenModal type="issue" basketDetails={basketDetails} onClose={() => setShowIssueModal(false)} />
                    </div>
                </div>
            )}
            {showRedeemModal && (
                <div>
                    <div
                        onClick={() => setShowRedeemModal(false)}
                        className="absolute top-0 left-0 w-screen h-screen bg-gray-600 opacity-50"
                    ></div>
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <RedeemTokenModal basketDetails={basketDetails} onClose={() => setShowRedeemModal(false)} />
                    </div>
                </div>
            )}
        </>
    )
}

type TokenIconProps = {
    symbol?: string,
    logo?: string
}

export const TokenIcon = ({ logo, symbol }: TokenIconProps) => {
    return <div className="flex items-center gap-4 font-medium">
        {logo ? <img src={logo} className="h-7 w-7 rounded-full bg-red-50" /> : <div className="h-7 w-7 rounded-full bg-gray-200" />}
        {symbol && <p>{symbol}</p>}
    </div>
}

export default ExploreBasket
