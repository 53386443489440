import { useState } from 'react'
import Dashboard_Navbar from '../components/Dashboard_Navbar'

import { TokenIcon } from './ExploreBasket'
import ToggleSwitch from '../components/ToggleSwitch'

import { subscribeTxStatus } from '../utils/subscribeTxStatus'
import React from 'react'
import { ProfileImage } from 'components/ProfileImage'
import { baseToken } from 'constants/token'
import { createIndexToken } from 'contract/scripts'
import { useCurrentWallet } from 'WalletProvider'
import BigNumber from 'bignumber.js'
import { toast } from 'react-toastify'
import { handleUploadJsonToIpfs } from 'utils/uploadJsonToIpfs'
import { handleUploadFileToIPFS } from 'utils/uploadFileToIpfs'

export type tokenDetails = {
    tokenName?: string,
    tokenSymbol?: string,
    tokenDecimal?: string,
    tokenIcon?: string,
    tokenDescription?: string,
    creatorPercentage?: string,
}

function CreateBasket() {
    const [selectedTokens, setSelectedTokens] = useState<string[]>([])
    const [tokenDetails, setTokenDetails] = useState<tokenDetails>()
    const [selectedTokenValues, setSelectedTokenValues] = useState<Record<string, string>>({})
    const [image, setImage] = React.useState(null);

    const { provider, wallet } = useCurrentWallet()

    const handleTokenDetailsChange = (key: keyof tokenDetails, value: string) => {
        setTokenDetails((prev) => {
            if (prev && Object.keys(prev).length)   return {...prev, [key]: value};
            else return {[key]: value}
        })
    }

    const createAsset = async () => {
        let ipfsImageHash = ""
        let ipfsHash = ""

        try {
            ipfsHash = await handleUploadJsonToIpfs({data: tokenDetails.tokenDescription})
            if (image) ipfsImageHash = await handleUploadFileToIPFS(image, new Date().toTimeString())

            let underlyingTokensAmount = selectedTokens.map(t => {
                let value = selectedTokenValues[t]
                let decimals = baseToken[t].decimals
                return new BigNumber(value).mul(new BigNumber(10).pow(decimals));
            })
            let underlyingTokenDecimals = selectedTokens.map(t => baseToken[t].decimals)
            const txId = await createIndexToken(provider, wallet, selectedTokens, underlyingTokensAmount, 
                                                underlyingTokenDecimals, tokenDetails.tokenName, tokenDetails.tokenSymbol, ipfsImageHash, ipfsHash, Number(tokenDetails.tokenDecimal), parseInt(tokenDetails.creatorPercentage))
    
            subscribeTxStatus(txId, provider) 
        } catch (e) {
            console.log("Falied to Create Asset : ", e)
            toast.error("Failed to Create Asset")
        }                         
    };
    
    const handleRemoveToken = (token: string) => {
        const updatedSelectedTokens = selectedTokens.filter(t => t!=token)
        setSelectedTokens(updatedSelectedTokens)
        const updatedSelectedTokenValues: Record<string, string> = {}
        updatedSelectedTokens.forEach(t => { updatedSelectedTokenValues[t] = selectedTokenValues[t]})
        setSelectedTokenValues(updatedSelectedTokenValues)
    }

    return (
        <div className="flex flex-col min-h-screen items-center  bg-red-50">
            <Dashboard_Navbar navbarShadow />
            <div className='flex flex-col items-center pb-10 md:w-2/3 w-5/6 bg-white-100 rounded-xl my-48 font-mono'>
                <div className='flex w-full justify-between items-center p-10'>
                    <div className='text-red-600 text-2xl font-medium'>Create Fusion Token</div>
                    <button 
                        onClick={createAsset}
                        className="bg-red-600 hover:bg-red-700 text-white-100 font-bold py-2 px-6 rounded-lg cursor-pointer min-w-[140px]"
                    >
                        Create
                    </button>
                </div>
                <hr className='bg-red-50 w-5/6' />
                <div className='flex flex-col w-full gap-4 items-center'>
                    <div className='font-mono my-4 text-gray-600'>Add Details about the Fusion Token</div>
                    <div className='flex items-center justify-center w-5/6 gap-4'>
                        <div className='w-full'>Token Logo : </div>
                        <div className='w-full flex pl-5'><ProfileImage setImage={setImage} /></div>
                    </div>
                    <div className='flex items-center justify-center w-5/6 gap-4'>
                        <div className='w-full'>Display Name: </div>
                        <input
                            placeholder="Token Name"
                            value={tokenDetails?.tokenName ?? ""}
                            onChange={(e) => handleTokenDetailsChange("tokenName", e.target.value)}
                            className="rounded-md w-full text-base px-6 py-4 focus:outline-none border-b-[1px] border-gray-200"
                        />
                    </div>
                    <div className='flex items-center w-5/6 gap-4'>
                        <div className='w-full'>Token Symbol (Preferably 3 letters): </div>
                        <input
                            placeholder="Token Symbol"
                            value={tokenDetails?.tokenSymbol ?? ""}
                            onChange={(e) => handleTokenDetailsChange("tokenSymbol", e.target.value)}
                            className="rounded-md w-full text-base px-6 py-4 focus:outline-none border-b-[1px] border-gray-200"
                        />
                    </div>
                    <div className='flex items-center justify-center w-5/6 gap-4'>
                        <div className='w-full'>Token Decimals : </div>
                        <input
                            placeholder="Token Decimals"
                            value={tokenDetails?.tokenDecimal ?? ""}
                            onChange={(e) => handleTokenDetailsChange("tokenDecimal", e.target.value)}
                            className="rounded-md w-full text-base px-6 py-4 focus:outline-none border-b-[1px] border-gray-200"
                        />
                    </div>

                    <div className='flex items-center justify-center w-5/6 gap-4'>
                        <div className='w-full'>Token Description : </div>
                        <textarea
                            onChange={(e) => handleTokenDetailsChange("tokenDescription", e.target.value)}
                            className="px-4 py-3 rounded-lg outline-none border-[1px] border-gray-300 mt-3 w-full"
                            rows={4} // You can adjust the number of rows as needed
                            placeholder="Write a bit about yourself"
                        ></textarea>
                    </div>
                    <div className='flex items-center justify-center w-5/6 gap-4'>
                        <div className='w-full'>Creator Percentage : </div>
                        <input
                            placeholder="Creator Percentage ( 1 - 10 % )"
                            value={tokenDetails?.creatorPercentage ?? ""}
                            onChange={(e) => handleTokenDetailsChange("creatorPercentage", e.target.value)}
                            className="rounded-md w-full text-base px-6 py-4 focus:outline-none border-b-[1px] border-gray-200"
                            type="number" // Set input type to number
                            min="1"       // Set minimum allowed value to 1
                            max="10"      // Set maximum allowed value to 10
                            step="1" // Increment by 1 for each arrow press
                        />
                    </div>
                    <hr className='bg-red-600 my-8 w-5/6' />
                    <div className='text-red-600 font-medium text-lg'>Choose Underlying Tokens</div>
                    {/* <div className='text-gray-600 text-sm'>We source tokens from just.money </div> */}
                    <div className='flex flex-col items-center w-5/6'>
                        {
                            Object.entries(baseToken).map(([key, token]) => (
                                <div className="flex justify-between w-full py-6 px-10 ">
                                    <TokenIcon logo={token?.logo} symbol={token?.name} />
                                    <ToggleSwitch 
                                        id={token?.symbol!} 
                                        checked={selectedTokens.includes(key)}  
                                        onChange={() => selectedTokens.includes(key) ? handleRemoveToken(key) : setSelectedTokens((prev) => [...prev, key])}
                                    />
                                    {
                                        selectedTokens.includes(key) && 
                                        <input
                                            placeholder={`${token?.symbol} value`}
                                            value={selectedTokenValues[key] ?? ""}
                                            onChange={(e) => setSelectedTokenValues((prev) => ({...prev, [key]: e.target.value}))}
                                            className="rounded-md text-base px-2 focus:outline-none border-b-[1px] border-gray-200"
                                        />
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateBasket