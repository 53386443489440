import { BaseToken } from "types/token"
import tokenData from './tokens.json'

// export const baseToken: Record<string, BaseToken> = {
//     "TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9": {
//         symbol: "JST",
//         name: "JUST",
//         logo: JSTLogo,
//         decimals: 18,
//     },
//     "TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S": {
//         symbol: "SUN",
//         name: "SUN TOKEN",
//         logo: SUNLogo,
//         decimals: 18,
//     }, 
//     "TKkeiboTkxXKJpbmVFbv4a8ov5rAfRDMf9": {
//         symbol: "SUNOLD",
//         name: "SUN TOKEN",
//         logo: SUNOLDLogo,
//         decimals: 18,
//     },
//     "TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT": {
//         symbol: "USDJ",
//         name: "JUST Stablecoin",
//         logo: USDJLogo,
//         decimals: 18,
//     },
//     "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8": {
//         symbol: "USDC",
//         name: "USD Coin",
//         logo: USDCLogo,
//         decimals: 6,
//     },
// }

const getAllTokens = () => {
    const data:Record<string, BaseToken> = {}
    
    tokenData.tokens.forEach(t => {
        data[t.address] = {
            decimals: t.decimals,
            logo: t.logoURI,
            name: t.name,
            symbol: t.symbol,
        }
    })
    return data
}

export const baseToken = getAllTokens();